import {
    GET_KAV_START,
    GET_KAV_END,
    SET_KAV,
    GET_FLIGHTS,
    GET_FLIGHT,
    LOAD_MORE_FLIGHTS,
    ADD_FLIGHT,
    EDIT_FLIGHT,
    DELETE_FLIGHT,
    FLIGHT_ERROR,
    CLEAR_FLIGHT,
    ADD_FILES,
    GET_PDF_NAMES,
    LOADING_START,
    LOADING_END,
    DONE_UPLOADING,
    START_UPLOADING,
    UPLOAD_PROGRESS
} from './flightTypes';
import axios from 'axios';
import { setAlert } from '../alert/alertAction';
import { xml2json } from "xml-js";

window.Buffer = window.Buffer || require("buffer").Buffer;

//CONSTANTS
const CONFIG = {
    headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('token')
    }
};

export const setCurrentFlight = (flight) => async dispatch => {
    try {
        dispatch({
            type: GET_FLIGHT,
            payload: flight
        })
    } catch (err) {
        dispatch({
            type: FLIGHT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}

export const getFlights = (page = 1, limit = 10) => async dispatch => {
    try {
        dispatch({ type: LOADING_START })
        const res = await axios.get('/api/flight', { params: { page, limit } });
        dispatch({
            type: GET_FLIGHTS,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: FLIGHT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    } finally {
        dispatch({ type: LOADING_END })
    }
}


export const loadMoreFlights = (page, limit) => async dispatch => {
    try {
        dispatch({ type: LOADING_START })
        const res = await axios.get('/api/flight', { params: { page, limit } });
        dispatch({ type: LOAD_MORE_FLIGHTS, payload: res.data });
    } catch (err) {
        dispatch({
            type: FLIGHT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    } finally {
        dispatch({ type: LOADING_END })
    }
}

// check if flight exist by tripNumber
export const checkIfFlightExist = (tripNumber) => async dispatch => {
    try {
        dispatch({ type: LOADING_START })
        const res = await axios.get(`/api/flight/check/${tripNumber}`);
        dispatch({
            type: GET_FLIGHT,
            payload: res.data
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: FLIGHT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
        dispatch({ type: LOADING_END })
    }
}

export const getFlight = id => async dispatch => {
    try {
        dispatch({ type: LOADING_START })
        const res = await axios.get(`/api/flight/${id}`);
        dispatch({
            type: GET_FLIGHT,
            payload: res.data
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: FLIGHT_ERROR,
            payload: { msg: err.response.msg, status: err.response.status }
        });
        dispatch({ type: LOADING_END })
    }
}

export const addFlight = (currentState, formData) => async dispatch => {
    try {
        // check if the wysiwygf is string, if not force it to be one
        currentState.emailText = typeof currentState.emailText === 'string' ? currentState.emailText : '';
        dispatch({ type: START_UPLOADING });
        dispatch({ type: LOADING_START });
        // GET DATA FROM KAV
        const kavData = await getKavData(dispatch, currentState)
        if (kavData === -1) return -1
        currentState.kavData = kavData;

        // FILES
        const filesPath = await handleFileUpload(dispatch, currentState, formData)
        if (filesPath === -1) return -1

        // CONVERT EML TO PDF
        let converted = await axios.post('api/files/convert', currentState);
        //get names of pdf files
        const res = await axios.post('api/files/main', currentState, CONFIG);
        currentState.passengers = res.data.data;

        //cross information with Kav system
        await dispatch(setAlert('מצליב מידע מול מערכת קו', 'general'));
        await crossInformationBetweenKavAndUser(kavData, currentState.passengers);
        //check if one person has more than 1 file to attach
        currentState.passengers.forEach(firstPassenger => {
            currentState.passengers.forEach(secondPassenger => {
                if (firstPassenger?.name === secondPassenger?.name)
                    samePersonTwoFiles(firstPassenger, secondPassenger)
            })
        })

        //reduce the passenger array so there wont be duplication and double sms/mail
        currentState.passengers = currentState.passengers.reduce((res, itm) => {
            // Test if the item is already in the new array
            let result = res.find(item => JSON.stringify(item?.name) === JSON.stringify(itm?.name))
            // If not lets add it
            if (!result && itm !== null) return res.concat(itm)
            return res
        }, [])

        //send mail to user
        await dispatch(setAlert('מתחיל תהליך של שליחת מייל, זה יכול לקחת מספר רגעים עד לקבלת תשובה', 'general'));
        let mailSent = await axios.post('api/mail', currentState, CONFIG);
        currentState.mailSent = mailSent.data.msg
        currentState.passengers = mailSent.data.data;

        const zapRes = await sendFileAndContactsToZapier(currentState);

        // await for send sms
        // let smsSent = await sendSmsWithWhatsApp(currentState.passengers, currentState.folderName)
        // if (smsSent)
        //     currentState.smsSent = smsSent
        // else
        //     currentState.smsSent = 'לא נשלחו הודעות'

        //get names of pdf files
        const res3 = await axios.post('api/files/pdf-names', currentState, CONFIG);
        currentState.passengers = res3.data.data;
        currentState.pathToReport = res3.data.pathToReport;
        currentState.allMailReports = res3.data.sendMailResObject;

        const addFlightRes = await axios.post('/api/flight', currentState, CONFIG);
        if (filesPath?.pdfFiles) addFlightRes.data.pdfFiles = filesPath.pdfFiles;
        if (filesPath?.filesNames) addFlightRes.data.pdfName = filesPath.filesNames;
        await dispatch({
            type: ADD_FLIGHT,
            payload: addFlightRes.data
        });
        await dispatch(setAlert('נוצר בהצלחה', 'success'));
    } catch (err) {
        console.log(err);
        const errors = err?.response?.data;
        if (errors) await dispatch(setAlert('לא נוצר טיול', 'danger'));
        else await dispatch(setAlert('היית שגיאה - נא צרו קשר', 'danger'));
        dispatch({
            type: FLIGHT_ERROR,
            payload: { msg: err.response, status: 'danger' }
        });
    }
    finally {
        dispatch({ type: DONE_UPLOADING });
        dispatch({ type: LOADING_END })
        dispatch({ type: CLEAR_FLIGHT });
    }
}

// export const editFlight = (currentState, formData) => async dispatch => { console.log('no function') }

export const clearFlight = () => dispatch => { dispatch({ type: CLEAR_FLIGHT }); }

// const handleFileUpload = async (dispatch, currentState, formData) => {
const handleFileUpload = async (dispatch, currentState, formData) => {

    // Upload files to server
    try {
        const filesPath = await uploadFiles(dispatch, formData, currentState);
        if (filesPath === -1) return -1

        currentState.pdfName = filesPath.filesNames;
        currentState.pdfFiles = filesPath.pdfFiles;
        currentState.folderName = formData.get('folderName');

        if (currentState.folderName) {
            await dispatch(setAlert('הקבצים עלו בהצלחה', 'success'));
            return filesPath
        } else {

            throw new Error('בעיה בהעלאת הקבצים');
        }
    } catch (err) {
        console.log(err)
        dispatch(setAlert('בעיה בהעלאת הקבצים', 'danger'));
        dispatch({ type: CLEAR_FLIGHT });
        dispatch({ type: LOADING_END })
        return -1
    }
};

const processResponseData = (uploadPath, finaleData) => {
    finaleData.pdfFiles.push(uploadPath);
    let fileName = uploadPath.split('/');
    finaleData.filesNames.push(fileName[fileName.length - 1]);
};

export const uploadFiles = async (dispatch, formData, state = null) => {
    console.log('uploadFiles', formData)
    try {
        dispatch({ type: LOADING_START })
        const config = {
            // onUploadProgress: function (progressEvent) {
            //     var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            //     dispatch({
            //         type: UPLOAD_PROGRESS,
            //         payload: percentCompleted
            //     })
            // },
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-auth-token': localStorage.token
            }
        };


        const res = await axios.post(`/api/files/upload-pdf`, formData, config);
        let responseData = res.data.data;
        let finaleData = { pdfFiles: [], filesNames: [] };
        if (responseData.constructor === Array) {
            responseData.forEach(({ uploadPath }) => processResponseData(uploadPath, finaleData));
        } else {
            processResponseData(responseData, finaleData);
        }

        await dispatch({ type: ADD_FILES, payload: finaleData });
        await dispatch({ type: LOADING_END })
        return finaleData;
    }
    catch (err) {
        const errors = err.response.data.msg;
        await dispatch({
            type: FLIGHT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
        dispatch(setAlert('בעיה בהעלאת הקבצים', 'danger'));
        if (errors) dispatch(setAlert(errors, 'danger'))
        dispatch({ type: CLEAR_FLIGHT });
        dispatch({ type: LOADING_END })
        return -1
    }
}

export const getNameFromPdf = (pdfFiles) => async dispatch => {
    const body = { pdfFiles }
    try {
        await dispatch({ type: LOADING_START })
        const res = await axios.get(`/api/files/pdf-names`, body, CONFIG);
        await dispatch({
            type: GET_PDF_NAMES,
            payload: res.data.data
        });
        await dispatch({ type: LOADING_END })
        return res.data;
    } catch (err) {
        console.log(err)
        await dispatch({ type: LOADING_END })
    }
}

export const downloadReport = (folderPath) => async dispatch => {
    let body = { folderPath: `./routes/api/${folderPath}/report.xlsx` }
    let flag = false
    try {
        await dispatch({ type: LOADING_START })

        const res = await axios.post(`/api/files/report`, body, { responseType: 'blob' }, CONFIG);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        dispatch(setAlert('הקובץ ירד', 'success'));
        dispatch({ type: LOADING_END })
        return res.data;
    } catch (err) {
        if (err.response.status === 404 && !flag) {
            flag = true
            body = {
                folderPath: `./routes/api/${folderPath}/report-eng.xls`
            }
            try {//case first report wasnt found - we will try to download the original report
                const res = await axios.post(`/api/files/report`, body, { responseType: 'blob' }, CONFIG);
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `report.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                dispatch(setAlert('הקובץ ירד', 'success'));
                dispatch({ type: LOADING_END })
                return res.data;

            } catch (error) {
                await dispatch({ type: LOADING_END })
                dispatch(setAlert('לא נמצאו קבצים', 'danger'));
            }
        }
        await dispatch({ type: LOADING_END })
        dispatch(setAlert(err.message, 'danger'));
    }
}

export const deleteFlight = (id, folderName) => async dispatch => {
    try {
        if (window.confirm('Are you sure? This can NOT be undone!')) {
            const body = {
                folderName: folderName,
                id: id
            }
            const resFolder = await axios.delete(`/api/files`, { data: body }, CONFIG)
            const res = resFolder ? await axios.delete(`/api/flight/${id}`, { data: body }, CONFIG) : null;
            if (res) {
                dispatch({
                    type: DELETE_FLIGHT,
                    payload: id
                });
                dispatch(setAlert('הוסר בהצלחה', 'success'));
            }
        }
    } catch (err) {
        dispatch({
            type: FLIGHT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}

export const addControl = (currentState, formData) => async dispatch => {
    try {
        dispatch({ type: GET_KAV_START });
        dispatch({ type: LOADING_START })
        // GET DATA FROM KAV
        const kavData = await getKavData(dispatch, currentState)
        if (kavData === -1) return -1;

        currentState.kavData = kavData;
        dispatch({
            type: SET_KAV,
            payload: currentState.kavData.Root.Body.DocketList.Docket
        });
        return kavData
    } catch (err) {
        dispatch({ type: GET_KAV_END, });
    }
}


export const sendJoinToWhatsAppGroup = (currentState, formData) => {
    return async dispatch => {
        console.log("sendJoinToWhatsAppGroup called with:", currentState, formData);
        try {
            dispatch({ type: LOADING_START });
            debugger
            const res = await axios.post('/api/whatsapp/send-whatsapp-group', { currentState, mariaPhone: { _text: '0522027533' }, formData });
            console.log("Response from /api/whatsapp/send-whatsapp-group:", res.data);
            console.log("Type of res.data:", typeof res.data, "is array:", Array.isArray(res.data));

            // Determine the payload to iterate
            let payload = [];
            if (res.data && Array.isArray(res.data)) {
                payload = res.data;
            } else if (res.data && Array.isArray(res.data.data)) {
                payload = res.data.data;
            } else {
                console.log("Unexpected response format:", res.data);
            }

            const recipients = [];
            const errors = [];
            payload.forEach(item => {
                if (Array.isArray(item)) {
                    item.forEach(innerItem => {
                        if (innerItem.success === true) recipients.push(innerItem);
                        else errors.push(innerItem);
                    });
                } else {
                    if (item.success === true) recipients.push(item);
                    else errors.push(item);
                }
            });
            const result = { Recipients: recipients, Errors: errors };
            console.log("sendJoinToWhatsAppGroup result:", result);
            const resultStr = `סך האנשים שקיבלו את ההודעה: ${recipients.length}`;
            const resultStrFail = `סך האנשים שהנכשל: ${errors.length}`;

            const zapRes = await axios.post('/api/zapier/send-whatsapp-link', { currentState, recipients });
            if (zapRes.data.error) {
                dispatch(setAlert('שגיאה בשליחת לינק ל-zapier', 'danger'));
                dispatch({ type: LOADING_END });
                return result;
            }
            dispatch(setAlert(resultStr, 'success'));
            dispatch(setAlert(resultStrFail, 'danger'));
            dispatch({ type: LOADING_END });
            return result;
        } catch (err) {
            console.log("Error in sendJoinToWhatsAppGroup:", err);
            const result = { Recipients: null, Errors: [err] };
            dispatch({
                type: FLIGHT_ERROR,
                payload: {
                    msg: err.response?.statusText || err.message,
                    status: err.response?.status || 'error'
                }
            });
            return result;
        }
    };
};

//---helpers---
export const sendSmsWithWhatsApp = async (passengers, folderName) => {
    try {
        const data = { passengers, folderName }
        const res = await axios.post('/api/sms/send-sms-with-tickets', data);
        return res.data
    } catch (err) { console.log(err) }
}


export const setXmlToJson = async (xml) => {
    let tempXml2Json = xml2json(xml, { compact: true });
    return JSON.parse(tempXml2Json)
}

const MINIMUM_DIFFERENCE_TO_SEND = 10
const isDocketFullyPaid = (docket) => {
    if (Array.isArray(docket.Balance.Currency)) {
        const currencyList = docket.Balance.Currency
        let totalDifference = 0;
        for (let i = 0; i < currencyList.length; i++) {
            const currency = currencyList[i];
            totalDifference += Number(currency.Difference._text)
        }
        return totalDifference <= MINIMUM_DIFFERENCE_TO_SEND
    } else {
        return Number(docket.Balance.Currency.Difference._text) <= MINIMUM_DIFFERENCE_TO_SEND
    }
}

const setPassengerWithDocketData = (passenger, docket, pax) => {
    passenger['kavData'] = docket
    debugger
    passenger['isPaid'] = isDocketFullyPaid(docket) ? true : false;
    passenger['email'] = pax?.Email._text ? pax.Email._text : 'NO_EMAIL';
    // passenger['email'] = validateEmail(pax?.Email._text) ? pax.Email._text : 'NO_EMAIL';
    passenger['phone'] = pax?.Phone?._text ? pax.Phone._text : 'NO_PHONE';
    if (passenger.email === 'NO_EMAIL') {
        if (docket?.Paxes?.Pax?.length > 1) {
            docket?.Paxes?.Pax.forEach(pax => {
                if (pax.Email?._text) //Passenger exist in Pax
                    passenger['mailSentTo'] = pax?.Email._text ? pax.Email._text : 'NO_EMAIL';
            })
        } else {
            if (passenger?.Email?._text) //Passenger exist in Pax
                passenger['mailSentTo'] = pax?.Email._text ? pax.Email._text : 'NO_EMAIL';
        }
    }
}

//validate email
const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}


const crossInformationBetweenKavAndUser = async (kavData, userFlightData) => {
    if (!kavData) return userFlightData;
    if (!userFlightData) return kavData;

    let kavDataCopy = JSON.parse(JSON.stringify(kavData));

    for (let i = 0; i < userFlightData.length; i++) { // PASSENGERS LOOP
        const passenger = userFlightData[i];
        const dockets = Array.isArray(kavDataCopy?.Root?.Body?.DocketList?.Docket) ? kavDataCopy.Root.Body.DocketList.Docket : [kavDataCopy.Root.Body.DocketList.Docket];
        dockets.forEach(docket => {// DOCKET LOOP
            if (docket?.Paxes?.Pax?.length > 1) {
                docket?.Paxes?.Pax.forEach(pax => {
                    if (generateFullName(pax) === passenger?.name?.toUpperCase().trim() ||
                        generateReverseName(pax) === passenger?.name?.toUpperCase().trim()) { //Passenger exist in Pax
                        setPassengerWithDocketData(passenger, docket, pax)
                    }
                })
            } else {
                if (
                    generateFullName(docket.Paxes?.Pax) === passenger?.name?.toUpperCase().trim() ||
                    generateReverseName(docket.Paxes?.Pax) === passenger?.name?.toUpperCase().trim()
                )//Passenger exist in Pax
                    setPassengerWithDocketData(passenger, docket, docket?.Paxes?.Pax)
            }
        })
    }

    const dockets = Array.isArray(kavDataCopy?.Root?.Body?.DocketList?.Docket) ? kavDataCopy.Root.Body.DocketList.Docket : [kavDataCopy.Root.Body.DocketList.Docket];
    dockets.forEach(docket => {
        let docketFullyPaid = isDocketFullyPaid(docket);

        if (docket?.Paxes?.Pax?.length > 1) {
            docket?.Paxes?.Pax.forEach(pax => {
                let existFlag = false
                let nameReverse = generateReverseName(pax)
                let name = generateFullName(pax)
                userFlightData.forEach(passenger => {
                    if (nameReverse === passenger?.name?.toUpperCase().trim() || name === passenger?.name?.toUpperCase().trim()) //Passenger exist in Pax
                        existFlag = true
                })
                if (!existFlag)
                    userFlightData.push(setDummyPassenger(pax, name, docketFullyPaid, userFlightData))
            })
        } else {
            let existFlag = false
            let nameReverse = generateReverseName(docket.Paxes?.Pax)
            let name = generateFullName(docket.Paxes?.Pax)
            userFlightData.forEach(passenger => {
                if (passenger?.name?.toUpperCase().trim() === nameReverse || passenger?.name?.toUpperCase().trim() === name) //Passenger exist in Pax
                    existFlag = true
            })
            if (!existFlag)
                userFlightData.push(setDummyPassenger(docket, name, docketFullyPaid, userFlightData))
        }
    })
    return userFlightData
}
const generateFullName = (pax) => { return `${pax?.LastName._text.trim()} ${pax?.FirstName._text.trim()}`.toUpperCase().trim(); };
const generateReverseName = (pax) => { return `${pax?.FirstName._text.trim()} ${pax?.LastName._text.trim()}`.toUpperCase().trim(); };
const setDummyPassenger = (docket, name, docketFullyPaid, userFlightData) => {
    let dummyPassenger = {
        no: userFlightData.length,
        name: name,
        status: 6, //PASSENGER_IN_KAV_SYSTEM_BUT_NO_TICKET_WAS_PROVIDED
        kavData: docket,
        isPaid: docketFullyPaid,
        ticketName: { name: 'לא צורף כרטיס לנוסע/ת' },
        email: docket?.Paxes?.Pax?.Email._text ? docket.Paxes.Pax.Email._text : 'NO_EMAIL',
        phone: docket?.Paxes?.Pax?.Phone?._text ? docket.Paxes.Pax.Phone._text : 'NO_PHONE',
    }
    return dummyPassenger
}

const samePersonTwoFiles = (person, person2) => {
    if (person?.ticketName?.name === person2?.ticketName?.name) { return; }
    if (person.filesToSend) person.filesToSend = [...person.filesToSend, person2.ticketName.name]
    else person.filesToSend = [person.ticketName.name, person2.ticketName.name];
}

const sendFileAndContactsToZapier = async (currentState) => {
    debugger
    const data = {
        passengers: currentState.passengers,
        pdfFiles: currentState.pdfFiles,
        folderName: currentState.folderName,
        tripTitle: currentState.tripTitle,
        tripNumber: currentState.tripNumber,
    }
    try {
        const res = await axios.post('/api/zapier/send-tickets', data, CONFIG);
        return res.data;
    } catch (err) {
        console.log(err)
    }
}

export const formatDate = (fullDate) => {
    if (fullDate) {
        const date = fullDate.slice(0, 10)
        const time = fullDate.slice(11, 16)
        const dateFormatted = [date.split('-').reverse().join('-'), time]
        return dateFormatted
    }
}

export const uploadProgress = (file, progress) => ({
    type: UPLOAD_PROGRESS,
    payload: progress,
    meta: { file },
});

const getKavData = async (dispatch, currentState) => {
    try {
        const kavData = await axios.post('api/kav', { currentState: currentState });
        const xml = preprocessKavXml(kavData.data);
        if (xml.includes("error code")) {
            handleKavError(xml, dispatch)
            return -1;
        }

        dispatch(setAlert('התקבל מידע ממערכת קו', 'success'));
        let kavDataJson = await setXmlToJson(xml)
        changeEmailsFromKavSystemToCorrectFormat(kavDataJson);

        return kavDataJson
    } catch (error) {
        // handle error
        throw error;
    }
}
const preprocessKavXml = (xml) => { return xml.replace(/[\n\r]/g, '\\n').replace(/&/g, "&amp;").replace(/-/g, "&#45;"); };
const handleKavError = async (xml, dispatch) => {
    xml = xml.replace('<?xml version="1.0"?>', '');
    let a = xml.indexOf("<origionalMessage>"), b = xml.indexOf("</errorMessage");
    let firstSub = xml.substring(0, a), secondSub = xml.substring(b, xml.length)
    xml = firstSub + secondSub;
    let responseError = await setXmlToJson(xml)
    dispatch(setAlert('שגיאה בקבלת נתונים מהמערכת הקבוצתית/קו', 'danger'));
    dispatch(setAlert(`השגיאה: ${responseError.errorMessage.error._attributes.code}`, 'danger'));
    dispatch({ type: CLEAR_FLIGHT });
    dispatch({ type: LOADING_END });
}
export const changeEmailsFromKavSystemToCorrectFormat = (kavData) => {
    if (!kavData) return null

    const dockets = Array.isArray(kavData.Root.Body.DocketList.Docket) ? kavData.Root.Body.DocketList.Docket : [kavData.Root.Body.DocketList.Docket];
    dockets.forEach(docket => {
        if (Array.isArray(docket.Paxes.Pax)) {
            docket.Paxes.Pax.forEach(pax => {
                if (Object.keys(pax.Email).length !== 0 && pax.Email._text) { pax.Email._text = pax.Email._text.replace(/[|]/g, "_") }
            })
        } else {
            if (Object.keys(docket.Paxes.Pax.Email).length !== 0) { docket.Paxes.Pax.Email._text = docket.Paxes.Pax.Email._text.replace(/[|]/g, "_") }
        }
    })
}