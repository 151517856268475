import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { addControl, sendJoinToWhatsAppGroup } from '../../../store/flight/flightAction'

import WhatsappDemo from './WhatsappDemo';
import ShowPassengerWithNoPhone from './ShowPassengerWithNoPhone';
import Alert from '../../layout/Alert';

const WhatsAppGroup = () => {
    const dispatch = useDispatch();
    const authState = useSelector(state => state.auth);
    var tzoffset = (new Date(0)).getTimezoneOffset() - 7200000; //offset in milliseconds

    const [loading, setLoading] = useState(false)
    const [res, setRes] = useState({})
    const [recipientsCount, setRecipientsCount] = useState(null);
    const [errorCount, setErrorCount] = useState(null);


    const [tripState, setTripState] = useState({
        whatsapp_group_URL: '',
        whatsapp_group_id: '',
        is_flight: true,
        tripGuider: '',
        tripNumber: '',
        freeText: '',
        msg: '',
        error: '',
        urlForWhatsAppGroup: '',
        dateFormatted: '',
        uploadEnd: false,
        kav: {},
    })
    const { tripNumber, tripGuider, freeText, kav, whatsapp_group_id, whatsapp_group_URL } = tripState

    useEffect(() => {
        if (res?.Recipients)
            setRecipientsCount(res?.Recipients);
        if (res?.Errors)
            setErrorCount(res?.Errors);
    }, [res]);
    useEffect(() => {

    }, [tripState])


    const onChange = e => {
        const target = e.target;
        let value = target.type === 'select-one' ? target.value === "true" : target.value;
        setTripState({ ...tripState, [target.name]: value });
    }


    const onSubmitGetKav = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('tripNumber', tripNumber);
        formData.append('tripGuider', tripGuider);
        formData.append('freeText', freeText);
        let currentTripState = { ...tripState, tripDate: (new Date(Date.now() - tzoffset)).toISOString() }
        let t = await dispatch(addControl(currentTripState, formData))
        if (t === -1) return -1

        let tempMsg = `
נוסעים יקרים,
אנו מזמינים אתכם להצטרף לקבוצת הווטסאפ של משתתפי הטיול ${tripGuider ? tripGuider : ''}

בשלב הזה לא ניתן לשלוח הודעות, הקבוצה תיפתח לשיח אך ורק לאחר מפגש הקבוצה שיתקיים בזום ${freeText}.

להצטרפות לקבוצת הווטסאפ אנא לחצו כעת על הקישור הבא: https://chat.whatsapp.com/${tripState.whatsapp_group_URL}, לאחר מכן לחצו על הכפתור "הצטרף לקבוצה"
ואשרו את הצטרפותכם.

בברכה,
צוות בשביל הזהב וקרוזתור
`
        setTripState({ ...tripState, kav: t?.Root?.Body?.DocketList?.Docket, msg: tempMsg })
    }
    // ${ tripNumber } בהדרכת ${ tripGuider } אנא לחץ על הקישור 
    const onSubmitSendWhatsapp = async (e) => {
        e.preventDefault();
        console.log("onSubmitSendWhatsapp triggered with tripState:", tripState);
        const formData = new FormData();
        formData.append('tripNumber', tripState.tripNumber);
        formData.append('tripGuider', tripState.tripGuider);
        formData.append('freeText', tripState.freeText);
        setLoading(true);
        let answer = await dispatch(sendJoinToWhatsAppGroup(tripState, formData));
        console.log("Receive answer in onSubmitSendWhatsapp:", answer);
        setRecipientsCount(answer?.Data?.Recipients);
        setLoading(false);
        setRes(answer);
    }

    const liStyle = {
        padding: '0.3rem',
        background: '#f19da4',
        border: '1px solid',
        borderRadius: '5px',
        margin: '0.3rem 0'
    };

    const resultToShow = res && Object.keys(res)?.length === 0 ? null : (
        <div className='res'>
            <p className='res__title'>{res?.Errors?.length === 0 ? 'ההודעה נשלחה בהצלחה!' : 'ההודעה לא נשלחה!'}</p>
            {res?.Recipients?.length > 0 && (
                <p className='res__success'>מס׳ אנשים שקיבלו את ההודעה: {res?.Recipients?.length}</p>
            )}
            {res?.Errors?.length > 0 && (
                <div>
                    <p className='res__fail'>מס׳ אנשים שלא קיבלו את ההודעה: {res?.Errors?.length}</p>
                    <ul className='res__errors'>
                        {res?.Errors?.map((error, index) => (
                            <li key={index} style={liStyle}>{error.error} , {error.phone}</li>

                        ))}
                    </ul>
                </div>
            )}
        </div>
    );


    const turType = tripState.is_flight ? "TURG" : "TURC"
    const styleForLabel = { marginInlineStart: `7rem` }

    const resetAll = () => {
        setTripState({
            whatsapp_group_URL: '',
            whatsapp_group_id: '',
            is_flight: true,
            tripGuider: '',
            tripNumber: '',
            freeText: '',
            msg: '',
            error: '',
            urlForWhatsAppGroup: '',
            dateFormatted: '',
            uploadEnd: false,
            kav: {},
        })
        setRes({})
        setRecipientsCount(null)
        setErrorCount(null)
    }
    if (!authState.isAuthenticated) {
        return <Navigate to='/login' />
    }
    if (authState?.user?.role === 'admin') {
        return <Navigate to='/' />
    }
    return (
        <>
            <div className='flex whatsappgroup'>
                <div className='right'>
                    <div className='flex'>
                        <h1>טופס שליחת הודעת וואטסאפ לפי מספר טיול</h1>
                        <div className="control-top-info">

                            <div className="input-container">
                                <input onChange={onChange} className='input form__field' id="tripNumber" name="tripNumber" type="text" value={tripNumber} />
                                <label htmlFor="tripNumber" className="label-name">קוד טיול:</label>
                                <span style={styleForLabel}>{`*ישלח למערכת קו "${tripNumber ? tripNumber.toUpperCase() : 'RN:2206'}" עם ${turType} `}</span>
                            </div>
                            <div className="input-container">
                                <select id="is_flight" onChange={onChange} name="is_flight" defaultValue={tripState.is_flight ? "TURG" : "TURC"}>
                                    <option value="true">TURG</option>
                                    <option value="false">TURC</option>
                                </select>
                            </div>

                            <div className='btns flex flex-between'>
                                <button className='btn btn-primary' onClick={onSubmitGetKav} >חפש טיול</button>
                                <button className='btn btn-warning' onClick={resetAll} >נקה הכל</button>
                            </div>
                        </div>
                    </div>

                    {kav && Object.keys(kav).length > 0 &&
                        <>
                            <div className="input-container">
                                <input onChange={onChange} className='input form__field' id="tripGuider" name="tripGuider" type="text" value={tripGuider} />
                                <label htmlFor="tripGuider" className="label-name"> שם הטיול</label>
                            </div>

                            <div className="input-container">
                                <input onChange={onChange} className='input form__field' id="freeText" name="freeText" type="text" value={tripState.freeText} />
                                <label htmlFor="freeText" className="label-name"> טקסט חופשי להודעה</label>
                            </div>

                            <div className="input-container">
                                <input onChange={onChange} className='input form__field' id="whatsapp_group_URL" name="whatsapp_group_URL" type="text" value={whatsapp_group_URL} />
                                <label htmlFor="whatsapp_group_URL" className="label-name"> לינק לקבוצת whatapp</label>
                            </div>
                            <button className='btn btn-primary' onClick={onSubmitSendWhatsapp} disabled={tripState.tripGuider && tripState.whatsapp_group_URL ? false : true}>שלח הודעה</button>
                        </>
                    }
                </div>

                <div className='left'>
                    {resultToShow ? <h1>תוצאות</h1> : null}
                    {resultToShow ? resultToShow :
                        <div className='message-container'>
                            <div className='message-text'>
                                {
                                    Object.keys(kav).length > 0 && <WhatsappDemo tripNumber={tripNumber} tripGuider={tripGuider} freeText={freeText} whatsapp_group_id={whatsapp_group_id} whatsapp_group_URL={whatsapp_group_URL} />
                                }
                            </div>
                        </div>
                    }
                </div>


                {
                    loading && <div className='loading'>
                        <div className='loading__container'>
                            <div class='loading__circle'></div>
                        </div>
                    </div>
                }
                {/* {
                    resultToShow
                } */}

            </div>
            <div className='whatsppDocket'>
                <ShowPassengerWithNoPhone kav={kav} res={res} />
            </div>

            <Alert />
        </>
    )
}

export default WhatsAppGroup

